import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { setConfig as setClerkConfig } from '#mrktbox/utils';

import defaults from '../defaults.json';

const ConfigContext = createContext({
  config : defaults,
  ready : false,
  default : true,
});

interface ConfigProviderProps {
  children : React.ReactNode;
}

export function ConfigProvider({ children } : ConfigProviderProps) {
  const outer = useContext(ConfigContext);

  const [config, setConfig] = useState(() => {
    try {
      const cache = localStorage.getItem('config');
      return cache ? JSON.parse(cache) : defaults;
    } catch (e) {
      console.error('Failed to load cached config.json');
      return defaults;
    }
  });

  const [fetched, setFetched] = useState(false);
  const [ready, setReady] = useState(false);

  const context = useMemo(() => ({
    config: outer.default ? config : outer.config,
    ready: outer.default ? ready : outer.ready,
    default: false,
  }), [config, ready, outer]);

  useEffect(() => {
    if (!outer.default || fetched) return;
    fetch('/config.json', { cache: 'reload' })
      .then((response) => response.json())
      .then((config) => {
        try { localStorage.setItem('config', JSON.stringify(config)); }
        catch (e) { console.error('Failed to cache config.json'); }
        setConfig(config);
        setClerkConfig({ domain : config.settings.clerk.domain });
        setFetched(true);
      })
      .catch(() => console.error('Failed to fetch config.json'));
  }, [fetched, outer]);

  useEffect(() => {
    if (!outer.default) return;
    try {
      if (localStorage.getItem('config')) {
        setReady(true);
        return;
      }
    } catch (e) { }
    setReady(fetched);
  }, [fetched, outer]);

  useEffect(() => {
    if (!outer.default) return;
    setClerkConfig({
      domain : config.settings.clerk.domain
        || process.env.REACT_APP_DOMAIN
        || '',
    });
  }, [config, outer]);

  return (
    <ConfigContext.Provider value={context}>
      { children }
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
